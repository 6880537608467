import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Button, InputGroup, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Spinner } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudSearchAction, ICrudGetAllAction, TextFormat } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentDetail, postDocument, signDocument, approveDocument, viewPDF, rejectDocument } from '../document/document.reducer';
import { IDocument } from 'app/shared/model/document.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import SignaturePad from 'react-signature-canvas'
import axios from 'axios';
import { BlobProvider, pdf, PDFViewer } from '@react-pdf/renderer';
import PreviewPDFComponent from 'app/modules/pdf/preview';
import XCircleIcon from 'app/component/x-circle-icon';
import CheckCircleIcon from 'app/component/check-circle-icon';
import dataURLtoBlob from 'blueimp-canvas-to-blob'
import { Document, pdfjs } from 'react-pdf';
import createBrowserHistory from 'history/createBrowserHistory';
import { toast } from 'react-toastify';
import DragUploadIcon from 'app/component/upload-drag-icon';
import EyeIcon from 'app/component/eye-icon';
import EyeSmallIcon from 'app/component/eye-small-icon';
import EyeSmallIconDark from 'app/component/eye-small-icon-dark';
import EyeSmallIconDarkHide from 'app/component/eye-small-icon-dark-hide';
import CloseDeleteIcon from 'app/component/close-delete-icon';
import SaveDraftIcon from 'app/component/save-draft-icon';
import FilesDragAndDrop from 'app/component/dragUpload';
import UploadFileIcon from 'app/component/upload-icon';
import { Box, CircularProgress } from '@mui/material';
import { flatMap, result } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import './previewapproval-style.scss'
import {isValidFileUploaded} from "app/entities/document/utils";

const reader = new FileReader()

const history = createBrowserHistory({ forceRefresh: true });

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export interface Props {
  getDocumentDetail?: any,
  match?: any,
  viewPDF?: any,
  detaildocument?: any,
  signDocument?: any,
  approveDocument?: any,
  blobPreview?: any,
  filePDFSign?: any,
  rejectDocument?: any,
  PreviewPDFComponent?: any,
  history?: any
}

export const PreviewAcknowledgeApproval: React.FC<Props> = (props) => {

  const [search, setSearch] = useState('');
  const [fileblob, setFileblob] = useState(null);
  const [showModalACK, setShowModalACK] = useState(false)
  const [showModalRejectACK, setShowModalRejectACK] = useState(false)
  const [showModalSignACK, setShowModalSignACK] = useState(false)
  const [showModalSendApprove, setShowModalSendApprove] = useState(false)
  const [progressApproval, setProgressApproval] = useState(false)
  const [notesApprove, setNotesApprove] = useState('')
  const [notesReject, setNotesReject] = useState('')
  const [previewApprove, setPreviewApprove] = useState(false)
  const [manualFlag, setManualFlag] = useState(false)
  const [typeTransaction, setTypeTransaction] = useState(1) // 1 -> approval / 2 -> reject
  const [progressApprove, setProgressApprove] = useState(false)
  const [progressReject, setProgressReject] = useState(false)
  const [listAttachment, setListAttachment] = useState<any>([])
  const [showManualApprove, setManualApprove] = useState<any>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [pin, setPin] = useState<any>("")
  const [files, setFiles] = React.useState(null);
  const [urlImage, setUrlImage] = useState<any>(null)
  const [notesManual, setNotesManual] = useState<any>("")
  const [progressManual, setProgressManual] = useState<any>(false)
  const [urlBlob, setUrlBlob] = useState<any>("")
  const [loadingDraftFile, setLoadingDraftFile] = useState(false)
  const [numberFile, setNumberFile] = useState<any>("")
  const [typeValue, setTypeValue] = useState('');

  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const signCanvas = useRef<any>();

  useEffect(() => {
    // const windowUrl = window.location.search;
    // const params = new URLSearchParams(windowUrl);
    // setNumberFile(params.get("fileNumber"))
    getDetailDocument(props.match.params.id)
    getListAttachment();
  }, []);



  // useEffect(() => {
  //   axios.get(`/services/documentservice/api/dms/get_document_for_approval?fileId=${props.match.params.id}`)
  //     .then(res => {

  //     }).catch(err => {
  //       toast.error(translate(`${err.response.data.message}`), {
  //         toastId: err.response.data.message,
  //         position: "top-left",
  //         autoClose: 8000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       if (err.response.status == 401) {
  //         history.push(`/preview/only/pdf/${props.match.params.id}`)
  //       }
  //       if (err.response.status === 404) {
  //         history.push('/404')
  //       }
  //     })
  // }, []);

  const clear = () => signCanvas.current.clear();

  const save = () => {
    let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    // console dataURLtoBlob(basse64)
  }

  const handleCloseACK = () => {
    setNotesApprove('')
    setShowModalACK(false)
    setShowPassword(false)
    setManualApprove(false)
  };

  const handleOpen = () => {
    setShowModalACK(true)
  }

  const handleCloseReject = () => {
    setNotesReject('')
    setShowModalRejectACK(false)
  };

  const handleOpenReject = () => {
    setShowModalRejectACK(true)
  }

  const resetAction = () => {
    setNotesApprove('')
    setNotesReject('')
    setPreviewApprove(false)
    setManualFlag(false)
  }

  const handleCloseSign = () => {
    setShowModalSignACK(false)
  };

  const handleOpenSign = () => {
    setShowModalSignACK(true)
  }

  const renderViewPDF = (id) => {
    props.viewPDF(id)
  }

  const submit = (datadokumen) => {
    if (datadokumen.isSigned == false) {
      pdf(<PreviewPDFComponent
        from={props.detaildocument?.senderBranchName}
        title={props.detaildocument?.fileNumber}
        regarding={props.detaildocument?.regarding}
        date={props.detaildocument?.date}
        to={props.detaildocument?.to}
        fileContent={props.detaildocument?.fileContent}
        cc={props.detaildocument?.cc}
        dataDocument={props.detaildocument}
        acknowledge={props.detaildocument?.acknowledgeList}
      />).toBlob().then(res => {
        let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        let signFile = dataURLtoBlob(basse64)
        props.signDocument(res, signFile, props.match.params.id)
        setShowModalSignACK(false)
      })
    } else {
      let basse64 = signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
      let signFile = dataURLtoBlob(basse64)
      props.signDocument(props.blobPreview, signFile, props.match.params.id)
      setShowModalSignACK(false)
    }
  }

  const submitApprove = async (datadokumen) => {

    const payloadData = {
      id: parseInt(props.match.params.id),
      notes: notesApprove
    };
    const encoded = encodeURIComponent(JSON.stringify(payloadData));
    const encodedValue = encoded.replace("!", '%21').replace("'", '%27').replace("*", '%2A').replace("(", '%28').replace(")", '%29');

    const formData = new FormData();
    formData.append("currentPinApproval", pin)
    setProgressApproval(true)
    axios.post(`/services/uaadocservice/api/personal-identity/login-approval`, formData).then(async (res) => {
      axios.get(`/services/documentservice/api/dms/confirm-approve?requestParam=${encodedValue}`, { responseType: 'blob' }).then(async (result) => {
        // await delayTime(3000)
        setProgressApproval(false)
        setShowModalSendApprove(true)
        // setShowModalACK(false)
        // setPreviewApprove(true)
      }).catch(async (err) => {
        setProgressApproval(false)
        const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
        const responseData = isJsonBlob(err.response?.data) ? await (err.response?.data)?.text() : err.response?.data || {};
        const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
        toast.error(translate(`${responseJson.message}`), {
          toastId: err.response.data.message,
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
    }).catch((err) => {
      setProgressApproval(false)
      toast.error(translate(`${err.response.data.message}`), {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const manualConfirm = () => {
    const dataManual = new FormData()
    dataManual.append("id", props.match.params.id)
    dataManual.append("filePdf", files)
    dataManual.append("notes", notesManual)
    axios.post('/services/documentservice/api/dms/confirm-approve-manual', dataManual, { responseType: 'blob' }).then(result => {
      setManualFlag(true)
      setManualApprove(false)
      setPreviewApprove(true)
      setPin("")
      setUrlBlob(URL.createObjectURL(result.data))
      setProgressManual(false)
      // setFiles(null)
      setNotesManual("")
      setUrlImage("")
    }).catch(async (err) => {
      setProgressManual(false)
      const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
      const responseData = isJsonBlob(err.response?.data) ? await (err.response?.data)?.text() : err.response?.data || {};
      const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
      toast.error(translate(`${responseJson.message}`), {
        toastId: err.response.data.message,
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const submitManualApprove = (datadokumen) => {
    setProgressManual(true)
    const formData = new FormData();
    formData.append("currentPinApproval", pin)
    axios.post(`/services/uaadocservice/api/personal-identity/login-approval`, formData).then(res => {
      manualConfirm()
    }).catch(err => {
      setProgressManual(false)
      toast.error(translate(`${err.response.data.message}`), {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
  }

  const getListAttachment = () => {
    setLoadingDraftFile(true)
    axios.get(`/services/documentservice/api/dms/get_attachment_from_gcp?fileId=${props.match.params.id}`)
      .then(result => {
        setLoadingDraftFile(false)
        setListAttachment(result.data.data.content)
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`))
        setLoadingDraftFile(false)
      })
  }

  const handlerChangeImage = (data) => {
    if (!data) return
    if (!isValidFileUploaded(data)) {
      toast.error('File type is not supported. Only PDF file is allowed', {
        position: "top-left",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (!validateSize(data)) {
        toast.error(translate('error.errordocservice.message.error39'), {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setFiles(data)
        setUrlImage(URL.createObjectURL(data))
      }
    }
  }

  function validateSize(input) {
    const fileSize = input.size / 1024 / 1024; // in MiB
    if (fileSize > 20) {
      return false
    } else {
      return true
    }
  }

  const getDetailDocument = (id) => {
    axios.get('/services/documentservice/api/documents/get-detail/new/' + id)
      .then(res => {
        console.log('data detail', res)
        setNumberFile(res.data.data.fileNumber)
        setTypeValue(res.data.data.type)
      }).catch(err => {
        toast.error(translate(`${err.response.data.message}`), {
          toastId: err.response.data.message,
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (err.response.status == 401) {
          // history.push(`/preview/only/pdf/${id}`)
          history.push('/new/401')
        }
        if (err.response.status == 404) {
          history.push('/new/404')
        }
      })
  }

  const sendApprove = async () => {
    const payloadData = {
      id: parseInt(props.match.params.id),
      notes: notesApprove
    };
    const encoded = encodeURIComponent(JSON.stringify(payloadData));
    const encodedValue = encoded.replace("!", '%21').replace("'", '%27').replace("*", '%2A').replace("(", '%28').replace(")", '%29');
    console.log('encodedValue 8')

    setProgressApprove(true)
    axios.post(`/services/documentservice/api/dms/send-approve?requestParam=${encodedValue}`)
      .then(async (res) => {
        // await delayTime(2000)
        setProgressApprove(false)
        setShowModalSendApprove(false)
        setShowModalACK(false)
        toast.success('Sukses Approve Dokumen', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.history.back()
      }).catch(err => {
        setProgressApprove(false)
        toast.error(translate(`${err.response.data.message}`), {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
  }

  const sendApproveManual = async () => {
    setProgressApprove(true)
    const formApproveManual = new FormData()
    formApproveManual.append("id", props.match.params.id)
    formApproveManual.append("filePdf", files)
    formApproveManual.append("notes", notesManual)
    axios.post(`/services/documentservice/api/dms/send-approve-manual`, formApproveManual)
      .then(async (res) => {
        await delayTime(2000)
        setProgressApprove(false)
        setFiles(null)
        toast.success('Sukses Approve Dokumen', {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.history.back()
      }).catch(err => {
        setProgressApprove(false)
        toast.error(translate(`${err.response.data.message}`), {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
  }

  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  const submitReject = async (datadokumen) => {
    const payloadData = {
      id: parseInt(props.match.params.id),
      notes: notesReject
    };
    const encoded = encodeURIComponent(JSON.stringify(payloadData));
    const encodedValue = encoded.replace("!", '%21').replace("'", '%27').replace("*", '%2A').replace("(", '%28').replace(")", '%29');
    console.log('encodedValue 11')

    await setProgressReject(true)
    const formData = new FormData();
    formData.append("currentPinApproval", pin)
    axios.post(`/services/uaadocservice/api/personal-identity/login-approval`, formData)
      .then(async (res) => {
        axios.post(`services/documentservice/api/dms/confirm-reject?requestParam=${encodedValue}`).then(async (res) => {
          await delayTime(2000)
          await setProgressReject(false)
          await setShowModalRejectACK(false)
          toast.success('Sukses Reject Dokumen', {
            position: "top-left",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.history.back()
        }).catch(async (err) => {
          setShowModalRejectACK(false)
          await delayTime(2000)
          await setProgressReject(false)
          toast.error(translate(`${err.response.data.message}`), {
            position: "top-left",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
      }).catch((err) => {
        toast.error(translate(`${err.response.data.message}`), {
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setProgressReject(false)
      })
  }

  let datadetail = props.detaildocument

  const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const openFilePreview = (base64, filetype) => {
    const blob = b64toBlob(base64, filetype);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl)
  }

  const manualApprove = () => {
    setManualApprove(true)
  }

  const downloadFilePDF = () => {
    const token = localStorage.getItem('access_token');

    const payloadData = {
      id: parseInt(props.match.params.id),
      notes: notesApprove
    };
    const encoded = encodeURIComponent(JSON.stringify(payloadData));
    const encodedValue = encoded.replace("!", '%21').replace("'", '%27').replace("*", '%2A').replace("(", '%28').replace(")", '%29');
    console.log('encodedValue 5')

    const link = document.createElement('a');
    if (previewApprove && !manualFlag) {
      fetch(`/services/documentservice/api/dms/confirm-approve?requestParam=${encodedValue}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
          .then((response) => response.blob())
          .then((blob) => {
            link.href = URL.createObjectURL(blob);
            link.download = `DMS-${numberFile}.pdf`;
            document.body.appendChild(link);
            link.click();
            setTimeout(function () {
              document.body.removeChild(link);
            }, 100);
          }).catch(err => {
        toast.error(translate(`${err.response.data.message}`), {
          toastId: err.response.data.message,
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
    } else if (previewApprove && manualFlag) {
      link.href = urlBlob;
      link.download = `DMS-${numberFile}.pdf`;
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
      }, 100);
    } else if (!previewApprove && !manualFlag) {
      fetch(`/services/documentservice/api/dms/get_document_for_approval?fileId=${props.match.params.id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
          .then((response) => response.blob())
          .then((blob) => {
            const blobURL = URL.createObjectURL(blob);
            link.href = blobURL;
            link.download = `DMS-${numberFile}.pdf`;
            document.body.appendChild(link);
            link.click();
            setTimeout( function () {
              document.body.removeChild(link);
            }, 100);
          }).catch(err => {
        console.log(err);
        toast.error(translate(`${err.response.data.message}`), {
          toastId: err.response.data.message,
          position: "top-left",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
    }
  }

  if (props.detaildocument.isSigned == false) {
    return (
      <div className="container">
        <Row>
          <Col>
            <h2>Acknowledge</h2>
            <p>File Preview Acknowledge</p>
          </Col>
        </Row>
        <PDFViewer style={{ width: '100%', height: '1000px' }} >
          <PreviewPDFComponent
            from={props.detaildocument?.senderBranchName}
            title={props.detaildocument?.fileNumber}
            regarding={props.detaildocument?.regarding}
            date={props.detaildocument?.date}
            to={props.detaildocument?.to}
            fileContent={props.detaildocument?.fileContent}
            cc={props.detaildocument?.cc}
            dataDocument={props.detaildocument}
            acknowledge={props.detaildocument?.acknowledgeList}
            notesApprove={notesApprove}
            notesReject={notesReject}
          />
        </PDFViewer>
        <Row className='mb-4 mt-4'>
          <Col>
          </Col>
          <Col className='text-right' xs="12" sm="12" >
            <button className='btn btn-danger mr-2 px-3' onClick={() => handleOpenReject()}><XCircleIcon className='mr-1' />Reject</button>
            <button className='btn btn-warning mr-2 px-4' onClick={() => handleOpenSign()}>Sign</button>
            <button className='btn btn-success px-3' onClick={() => handleOpen()} ><CheckCircleIcon className='mr-1' /> Approve</button>
          </Col>
        </Row>
        <Modal isOpen={showModalACK} toggle={handleCloseACK} size="lg">
          <ModalHeader toggle={handleCloseACK}>
            <h2>Approval Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Dokumen Ini Disetujui Dengan Catatan :</span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question">
            <div className='mb-2 ml-2 mr-2'>Notes</div>
            {/* <FormGroup> */}
            <div className='mb-2 ml-2 mr-3'>
              <Input
                rows={5}
                style={{ resize: 'none' }}
                id="exampleText"
                name="text"
                type="textarea"
                maxLength={255}
                value={notesApprove}
                onChange={(e) => setNotesApprove(e.target.value)}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '15px' }}>{255 - notesApprove.length}/255</div>
            </div>
            {/* </FormGroup> */}
          </ModalBody>
          <ModalFooter>
            <Row className='w-100'>
              <Col lg={6}>
              </Col>
              <Col lg={6} className="text-right">
                <Button color="secondary" onClick={handleCloseACK} className="mr-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button color="success" onClick={() => submitApprove(props.detaildocument)}>
                  Confirm Approve
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showModalRejectACK} toggle={handleCloseReject} size="lg">
          <ModalHeader toggle={handleCloseReject}>
            <h2>Reject Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Dokumen Ini Ditolak Dengan Alasan :</span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question">
            <FormGroup>
              <Input
                rows={5}
                style={{ resize: 'none' }}
                id="exampleText"
                name="text"
                type="textarea"
                maxLength={255}
                value={notesReject}
                onChange={(e) => setNotesReject(e.target.value)}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '15px' }}>{255 - notesReject.length}/255</div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Row className='w-100'>
              <Col lg={6}>
              </Col>
              <Col lg={6} className="text-right">
                <Button color="secondary" onClick={handleCloseReject} className="mr-2">
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitReject(props.detaildocument)} disabled={!notesReject || progressReject}>
                  {progressReject == true ? <Spinner size="sm" color="#fff" /> : <></>}
                  Confirm Rejectdsd
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showModalSignACK} toggle={handleCloseSign} size="sm">
          <ModalHeader toggle={handleCloseSign}>
            <h2>Sign Confirmation</h2>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question" style={{ backgroundColor: '#fff', justifyContent: 'center', display: 'flex' }}>
            <div style={{ backgroundColor: '#bdbdbd', width: 150, height: 100 }}>
              <SignaturePad
                ref={signCanvas}
                canvasProps={{ className: "signature__canvas", width: 150, height: 100, backgroundColor: '#bdbdbd' }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Row className='w-100'>
              <Col lg={12}>
              </Col>
              <Col lg={12} className="text-right">
                <Button color="secondary" onClick={handleCloseSign} className="mr-2 w-100 my-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button color="secondary" onClick={clear} className="mr-2 w-100 my-2">
                  Clear
                </Button>
                <Button color="warning" onClick={() => submit(props.detaildocument)} className="w-100 my-2">
                  Confirm Sign
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal
            isOpen={showModalSendApprove}
            size="md"
            centered={true}
            backdrop={"static"}>
          <ModalHeader>
            <div className="font-size-18 font-family-spoqa">
              Confirmation
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="ml-4 mr-5 mt-3 mb-3 font-family-spoqa">
              <div className="font-size-14 mt-3" style={{ textAlign: "center" }}>
                Do you want to approve and send this document?
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button disabled={progressApprove} color="light" style={{ border: '1px solid #000' }} onClick={() => {
              setShowModalSendApprove(false)
              // setProgressApproval(false)
            }} className="mr-2">
              Cancel
            </Button>
            <Button
                id="conf-send"
                disabled={progressApprove}
                style={{ borderColor: "#F37F26", backgroundColor: "#F37F26", color: "#fff" }}
                onClick={() => {
                  setShowModalSignACK(false)
                  setProgressApproval(false)
                  sendApprove()
                }}>
              <span>Send</span>
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  } else {
    const payloadData = {
      id: parseInt(props.match.params.id),
      notes: notesApprove
    };
    const encoded = encodeURIComponent(JSON.stringify(payloadData));
    const encodedValue = encoded.replace("!", '%21').replace("'", '%27').replace("*", '%2A').replace("(", '%28').replace(")", '%29');
    console.log('encodedValue 6')
    return (
      <div className="container">
        <Row>
          <Col>
            <h2>Approval</h2>
            <p>File Preview Approval</p>
          </Col>
        </Row>
        <div className="pdf" />
        {previewApprove && !manualFlag ? <iframe src={`${window.location.origin}/services/documentservice/api/dms/confirm-approve?requestParam=${encodedValue}#toolbar=0&navpanes=0&scrollbar=0`} style={{ width: '100%', height: '1000px' }} />
          : <></>
        }
        {
          previewApprove && manualFlag ? <iframe src={`${urlBlob}#toolbar=0&navpanes=0&scrollbar=0`} style={{ width: '100%', height: '1000px' }} /> : <></>
        }
        {
          !previewApprove && !manualFlag ? <iframe src={`${window.location.origin}/services/documentservice/api/dms/get_document_for_approval?fileId=${props.match.params.id}#toolbar=0&navpanes=0&scrollbar=0`} style={{ width: '100%', height: '1000px' }} /> : <></>
        }
        <div className="bg-white rounded shadow my-4 px-4 py-4">
          <h5>
            Attachment
          </h5>
          {
            listAttachment.length > 0
              ?
              listAttachment.map((item, index) => {
                return (
                  <div key={index} onClick={() => openFilePreview(item.file?.replace(/^[^,]+,/, ''), item.fileContentType)}>
                    <div>
                      <div className="py-2 pl-3 pr-2 rounded shadow my-2 d-inline-block" style={{ background: '#F5F5F5', color: '#3267E3', alignContent: 'c' }} key={`file-${index}`}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{cursor:'pointer'}}>
                            {item.fileName} ( {bytesToSize(item.fileSize)} )
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              :
              (loadingDraftFile == true
                ?
                <div className="text-center my-4">
                  <Box sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color='warning' />
                  </Box>
                </div>
                :
                <></>
              )
          }
        </div>
        <Row className='mb-4 mt-4'>
          <Col>
          </Col>
          {previewApprove ? <Col className='text-right' xs="12" sm="12" md="6" lg="6">
            <button className='btn btn-light mr-2 px-4' onClick={() => resetAction()} disabled={progressApprove}>Cancel</button>
            <button className='btn btn-primary px-3' onClick={!manualFlag ? sendApprove : sendApproveManual} disabled={progressApprove}> {progressApprove ? <Spinner color='#fff' size="sm" className="mr-1" /> : <></>}Send</button>
          </Col> : <Col className='mx-0 px-0' xs="12" sm="12" md="7" lg="9">
            <div className='w-100' style={{ display: 'flex', justifyContent: 'center' }}>
              {numberFile != null
                ?
                <Button className="btn btn-secondary ml-2 mr-2 mt-2" style={{ width: '180px', height: '40px' }} onClick={downloadFilePDF}>
                  <FontAwesomeIcon icon={faFileDownload} />
                  &nbsp;
                  Download
                </Button>
                : <></>}
              <div className="row w-100 mx-0">
                <div className="col-lg-4 col-md-6 col-xs-2 col-sm-2 text-center my-2">
                  <button className='btn btn-light w-100 text-center' style={{ border: '1px solid #000' }} onClick={() => window.history.back()}>Cancel</button>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-3 col-sm-3 text-center my-2">
                  <button className='btn btn-danger w-100' onClick={() => handleOpenReject()}><XCircleIcon className='mr-1' />Reject</button>
                </div>
                <div className="col-lg-4 col-md-6 col-xs-3 col-sm-3 text-center my-2">
                  <button className='btn btn-success w-100' onClick={() => handleOpen()} ><CheckCircleIcon className='mr-1' /> Approve</button>
                </div>
              </div>
            </div>
          </Col>}
        </Row>
        <Modal isOpen={showModalACK} toggle={handleCloseACK} size="lg" style={{ marginTop: '10%' }}>
          <ModalHeader toggle={handleCloseACK}>
            <h2>Approval Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Dokumen Ini Disetujui Dengan Catatan : </span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question">
            <div className='px-2 py-2'>
              <div className='mb-2 ml-2 mr-2'>Notes</div>
              {/* <FormGroup> */}
              <div className='mb-2 ml-2 mr-3'>
                <Input
                  rows={5}
                  style={{ resize: 'none' }}
                  id="exampleText"
                  name="text"
                  type="textarea"
                  value={notesApprove}
                  maxLength={255}
                  onChange={(e) => setNotesApprove(e.target.value)}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '15px' }}>{255 - notesApprove.length}/255</div>
              </div>
              {/* </FormGroup> */}
            </div>
          </ModalBody>
          <ModalFooter className='px-0'>
            <Row className='w-100'>
              <span className='mb-1'>PIN</span>
              <Col lg={4}>
                 <form onSubmit={e => { e.preventDefault(); }}>
                  <div style={{ border: '1px solid #C2C2C2', borderRadius:"2px", width:"100px"}}>
                    <input
                        value={pin}
                        name="textReason"
                        className={"font-family-spoqa"}
                        type={showPassword ? 'text' : 'password'}
                        style={{ padding: '8px', border:'0px', outline: "none",  width:"75px"}}
                        onChange={(e) => {
                          if (e.target.value.length < 7) {
                            setPin(e.target.value)
                          }
                        }}
                        disabled={progressApproval}
                    />
                    {
                      !showPassword ? <EyeSmallIconDark style={{position: 'absolute', top: '25%', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} /> : <EyeSmallIconDarkHide onClick={() => setShowPassword(!showPassword)} style={{ position: 'absolute', top: '25%', cursor: 'pointer' }} />
                    }
                  </div>
                </form>
              </Col>
              <Col lg={8} className="text-right">
                <Button color="light" style={{ border: '1px solid #000' }} onClick={handleCloseACK} className="mr-2">
                  <Translate contentKey="entity.action.cancel">Cancel </Translate>
                </Button>
                <Button color="success" onClick={() => submitApprove(props.detaildocument)} disabled={progressApproval || !pin || pin.length < 6}>
                  {progressApproval ? <Spinner color='#fff' size="sm" className='mr-1' /> : <></>}
                  Confirm Approve
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showModalRejectACK} toggle={handleCloseReject} size="lg" style={{ marginTop: '10%' }}>
          <ModalHeader toggle={handleCloseReject}>
            <h2>Reject Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Dokumen Ini Ditolak Dengan Alasan :</span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question">
            <div className='px-2 py-3'>
              <div className='mb-2 ml-2 mr-2'>Notes</div>
              {/* <FormGroup> */}
              <div className='mb-2 ml-2 mr-3'>
                <Input
                  rows={5}
                  style={{ resize: 'none' }}
                  id="exampleText"
                  name="text"
                  type="textarea"
                  maxLength={255}
                  value={notesReject}
                  onChange={(e) => setNotesReject(e.target.value)}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '15px' }}>{255 - notesReject.length}/255</div>
              </div>
              {/* </FormGroup> */}
            </div>
          </ModalBody>
          <ModalFooter className='px-0'>
            <Row className='w-100'>
              <span className='mb-1'>PIN</span>
              <Col lg={4}>
                  <form onSubmit={e => { e.preventDefault(); }}>
                  <div style={{ border: '1px solid #C2C2C2', borderRadius:"2px", width:"100px"}}>
                    <input
                        value={pin}
                        name="textReject"
                        className={"font-family-spoqa"}
                        type={showPassword ? 'text' : 'password'}
                        style={{ padding: '8px', border:'0px', outline: "none",  width:"75px"}}
                        onChange={(e) => {
                          if (e.target.value.length < 7) {
                            setPin(e.target.value)
                          }
                        }}
                        disabled={progressApproval}
                    />
                    {
                      !showPassword ? <EyeSmallIconDark style={{position: 'absolute', top: '25%', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} /> : <EyeSmallIconDarkHide onClick={() => setShowPassword(!showPassword)} style={{ position: 'absolute', top: '25%', cursor: 'pointer' }} />
                    }
                  </div>
                </form>
              </Col>
              <Col lg={8} className="text-right">
                <Button color="light" style={{ border: '1px solid #000' }} onClick={handleCloseReject} className="mr-2" disabled={progressReject}>
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>

                <Button color="danger" onClick={() => submitReject(props.detaildocument)} disabled={!notesReject || progressReject || !pin || pin.length < 6}>
                  {progressReject ? <Spinner size="sm" color="#fff" className='mr-1' /> : <></>}
                  Confirm Reject
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showModalSignACK} toggle={handleCloseSign} size="sm">
          <ModalHeader toggle={handleCloseSign}>
            <h2>Sign Confirmation</h2>
            <span style={{ fontSize: '12px' }}>Lorem ipsum dolor sit amet consectetur, adipisicing elit</span>
          </ModalHeader>
          <ModalBody id="documenttestApp.document.delete.question" style={{ backgroundColor: '#fff', justifyContent: 'center', display: 'flex' }}>
            <div style={{ backgroundColor: '#bdbdbd', width: 150, height: 100 }}>
              <SignaturePad
                ref={signCanvas}
                canvasProps={{ className: "signature__canvas", width: 150, height: 100, backgroundColor: '#bdbdbd' }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Row className='w-100'>
              <Col lg={12}>
              </Col>
              <Col lg={12} className="text-right">
                <Button color="secondary" onClick={handleCloseSign} className="mr-2 w-100 my-2">
                  <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
                <Button color="secondary" onClick={clear} className="mr-2 w-100 my-2">
                  Clear
                </Button>
                <Button color="warning" onClick={() => submit(props.detaildocument)} className="w-100 my-2">
                  Confirm Sign
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showManualApprove} toggle={handleCloseACK} size="lg" style={{ marginTop: '10%' }}>
          <ModalBody id="documenttestApp.document.delete.question" className=''>
            <div className="px-4 py-4">
              <div className='mb-3'>
                <h2>Manual Approve</h2>
              </div>
              <div className='mt-4 mb-2'>
                <span className=''>Upload File </span>
              </div>
              <div>
                {
                  urlImage ? <div style={{ height: 200, background: '#D4D4D4', position: 'relative', borderRadius: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className='d-block text-center'>
                      <div>
                        <UploadFileIcon style={{ height: 30 }} />
                      </div>
                      <div style={{ color: '#000', fontSize: 14 }}>{files?.name}</div>
                    </div>
                    <CloseDeleteIcon style={{
                      position: 'absolute',
                      top: -5,
                      right: -5,
                      cursor: 'pointer'
                    }} onClick={() => {
                      setUrlImage(null)
                      setFiles(null)
                    }} />
                  </div> : <></>
                }
              </div>
              {
                !urlImage ? <>
                  <div style={{ marginTop: 10 }}></div>
                  <label htmlFor={"file_picker"} id="drop_zone">
                    <DragUploadIcon />
                    <div>Drag and drop a file here</div>
                    <input
                      id="file_picker"
                      type="file"
                      // accept="application/pdf"
                      onChange={(ev) => {
                        handlerChangeImage(ev.target.files[0])
                      }}
                      style={{ display: "none" }}
                    ></input>
                  </label>
                  <span style={{ fontSize: '10px' }}> ( Upload .pdf file only, with max size 10MB)</span>
                </> : <></>
              }
              <div>
                <div className='mb-3 mt-4'>Notes</div>
                <div>
                  <Input
                    rows={5}
                    style={{ resize: 'none' }}
                    id="exampleText"
                    name="text"
                    type="textarea"
                    maxLength={255}
                    value={notesManual}
                    onChange={(e) => setNotesManual(e.target.value)}
                  />
                  <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '15px' }}>{255 - notesManual.length}/255</div>
                </div>
              </div>
            </div>
            <div>
              <Row className='px-4'>
                <div className='mb-1'>PIN</div>
                <Col lg={4}>
                    <form onSubmit={e => { e.preventDefault(); }}>
                    <div style={{ border: '1px solid #C2C2C2', borderRadius:"2px", width:"100px"}}>
                      <input
                          value={pin}
                          name="textReason"
                          className={"font-family-spoqa"}
                          type={showPassword ? 'text' : 'password'}
                          style={{ padding: '8px', border:'0px', outline: "none",  width:"75px"}}
                          onChange={(e) => {
                            if (e.target.value.length < 7) {
                              setPin(e.target.value)
                            }
                          }}
                          disabled={progressManual}
                      />
                      {
                        !showPassword ? <EyeSmallIconDark style={{position: 'absolute', top: '25%', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} /> : <EyeSmallIconDarkHide onClick={() => setShowPassword(!showPassword)} style={{ position: 'absolute', top: '25%', cursor: 'pointer' }} />
                      }
                    </div>
                  </form>
                </Col>
                <Col lg={8} className="text-right">
                  <Button color="light" style={{ border: '1px solid #000' }} onClick={() => { setManualApprove(false); setFiles(null); setNotesManual(""); setUrlImage("") }} className="mr-2" disabled={progressManual}>
                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                  </Button>
                  <Button color="primary" onClick={() => submitManualApprove(props.detaildocument)} disabled={!notesManual || progressManual || !pin || !files || pin.length < 6}>
                    {progressManual ? <Spinner size="sm" color="#fff" className='mr-1' /> : <></>}
                    Confirm
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        <Modal
            isOpen={showModalSendApprove}
            size="md"
            centered={true}
            backdrop={"static"}>
          <ModalHeader>
            <div className="font-size-18 font-family-spoqa">
              Confirmation
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="ml-4 mr-5 mt-3 mb-3 font-family-spoqa">
              <div className="font-size-14 mt-3" style={{ textAlign: "center" }}>
                Do you want to approve and send this document?
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button disabled={progressApprove} color="light" style={{ border: '1px solid #000' }} onClick={() => {
              setShowModalSendApprove(false)
              // setProgressApproval(false)
            }} className="mr-2">
              Cancel
            </Button>
            <Button
                id="conf-send"
                disabled={progressApprove}
                style={{ borderColor: "#F37F26", backgroundColor: "#F37F26", color: "#fff" }}
                onClick={() => {
                  setShowModalSignACK(false)
                  setProgressApproval(false)
                  sendApprove()
                }}>
              <span>Send</span>
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.entities,
  loading: document.loading,
  detaildocument: document.detaildocument,
  filePDFSign: document.filePDFsign,
  acknowledgelist: document.acknowledgelist,
  fileUrl: document.fileUrlBlob,
  blobPreview: document.fileBlobPreview
});

const mapDispatchToProps = {
  getSearchEntities,
  getEntities,
  getEntitiesByEmpId,
  getDocumentDetail,
  postDocument,
  signDocument,
  approveDocument,
  viewPDF,
  rejectDocument
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PreviewAcknowledgeApproval);
