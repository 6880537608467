import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './styles.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';
import IconCalendar from "app/component/icon-calendar";
import Pagination from '@mui/material/Pagination';
const list = [
    { value: 2 },
    { value: 5 },
    { value: 10 },
    { value: 15 },
    { value: 20 },
    { value: 25 },
]
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMui } from '@mui/material';

import React, { useRef, useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter, Button, Spinner } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSearchEntities, getEntities, getEntitiesByEmpId, getDocumentApproval, getDocumentAcknowledge, getDocumentSuratKeluar, getDocumentSuratMasuk } from '../document/document.reducer';
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import { useParams, useHistory } from "react-router-dom";
import ArrowRightIcon from 'app/component/arrow-right-icon';
import moment from 'moment-timezone';
import axios from 'axios'
import { size } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BottomSort from 'app/component/bottom-sort';
import Illustration1 from 'app/component/illustration-1';
import EditBlue from 'app/component/edit-blue';
import DeleteRed from 'app/component/delete-red';

import UploadDocumentListIcon from 'app/component/upload-document-karyawan';
import UserAddKaryawanIcon from 'app/component/user-add-karyawan';
import { useParams } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// @ts-ignore
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { baseUrlAPI } from './configWhistleblowing';

// import id from 'date-fns/locale/id';
export interface IHeaderProps {
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    ribbonEnv?: string;
    isInProduction?: boolean;
    isSwaggerEnabled?: boolean;
    currentLocale?: string;
    onLocaleChange?: Function;
    account?: any,
    logout?: any,
    location?: any
}
export interface IParams {
    key?: any,
}

export const ReportList = (props: IHeaderProps) => {
    const initialFormData = {
        listStatus: null,
        selectStatus: null,
    }
    let history = useHistory();
    const [sort, setSort] = useState<any>("full_name")
    const [ascending, setAscending] = useState<any>(true)
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [size, setSize] = useState<any>(10)
    const [dataDetailofJob, setDataDetailofJob] = useState<any>([])
    const [dataListofStatus, setDataListofStatus] = useState<any>([])
    const [formData, setFormData] = useState(initialFormData)
    const [dataListofWhistleblowing, setDataListofWhistleblowing] = useState<any>([])

    useEffect(() => {
        getWhistleblowing()
        getStatus()
    }, [page, size, totalPage, keyword, formData, sort, ascending, formData.selectStatus]);


    const token = localStorage.getItem('access_token');

    const getWhistleblowing = () => {

        let processStatus = !formData.listStatus ? "" : formData.listStatus
        let config = {
            mode: 'no-cors',
            method: 'GET',
            referrerPolicy: "strict-origin-when-cross-origin",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }
        axios.get(`${baseUrlAPI()}/whistleblow-reports?keyword=${keyword}&process_status=${processStatus}&page=${page}&limit=${size}&sort_by=${sort} ${!ascending ? 'DESC' : 'ASC'}`, config)

            .then((res) => {
                setDataListofWhistleblowing(res.data.data);
                setTotalPage(res.data.metadata.total_page);
            })
            .catch((err) => {
                toast.error(translate(`${err.response.data.message}`));
            });
    }

    const getStatus = () => {
        axios.get(`${baseUrlAPI()}/process-status/get-list`).then((res) => {
            const response = res.data.data
            const newStatus = response.map((obj, i) => ({ label: obj.process_status_name, value: obj.process_status_code }));
            let addStatus = [{ label: "All", value: 0 }, ...newStatus];
            setDataListofStatus(addStatus)
        }).catch((err) => {
            throw new Error('getStatus')
        })
    }
    interface filterDTO {
        start_date: String,
        end_date: String,
        investManagementId: String,
        search: String,
        page: number
    }
    const [filters, setFilter] = useState<filterDTO>({
        start_date: null,
        end_date: null,
        investManagementId: null,
        search: null,
        page: 1
    })

    const handleInput = (name, value) => {
        if (name == 'listStatus') {
            setFormData({ ...formData, selectStatus: null })
            setFormData({
                ...formData,
                [name]: value.value,
            })
            setPage(1)
            // getStatus()
        } else {
            setFormData({
                ...formData,
                [name]: value.value
            })
            setPage(1)
        }
    }

    // pagination
    const handleChange = (event) => {
        setPage(1)
        setSize(parseInt(event.target.value));
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const iframeRef = useRef(null);

    useEffect(() => {
        const setIframeHeight = () => {
            if (iframeRef.current) {
                const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
                const height = iframeDocument.body.scrollHeight + 'px';
                iframeRef.current.style.height = height;
            }
        };

        window.addEventListener('resize', setIframeHeight);
        setIframeHeight();

        return () => {
            window.removeEventListener('resize', setIframeHeight);
        };
    }, [dataDetailofJob.description]);

    return (
        <div className="px-5 pt-4" style={{ height: '100%' }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-28">Whistleblow Report</h1>
                    </div>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h1 className="font-family-spoqa mb-0 font-size-14">Your Dashboard to Employee Recruitment Excellence</h1>
                    </div>
                </div>
            </div>
            <div className="row my-4 justify-content-between font-family-spoqa">
                <div className="col-10 col-md-10 col-lg-10">
                    <div className="d-flex" style={{ flexGrow: 10 }}>
                        <div className="app-search w-100 py-0 bg-white">
                            <div className="position-relative">
                                <input type="text" className="form-control bg-white py-3" value={keyword} placeholder="Search Whistleblow" onChange={(e) => {
                                    if (e.target.value === '') {
                                        setKeyword(e.target.value)
                                        setPage(1)
                                        getWhistleblowing()
                                    } else {
                                        setKeyword(e.target.value)
                                        setPage(1)
                                    }
                                }} onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                        setPage(1)
                                        getWhistleblowing()
                                    }
                                }} />
                                <span className="bx bx-search-alt my-0"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2 col-md-2 col-lg-2">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <Select
                                name="status"
                                defaultValue={{ label: "All", value: 0 }}
                                value={dataListofStatus.filter((function (option: any) {
                                    return option.value == formData.listStatus
                                }))}
                                placeholder="Select Status"
                                options={dataListofStatus}
                                onChange={(e) => handleInput('listStatus', e)}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className="card font-family-spoqa">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped table-centered mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>No</th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('id_report')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>ID Report / Date</span>
                                            <span className='mt-1'>
                                                {(sort == "full_name" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "full_name" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "full_name") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('disclosure')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Disclosure</span>
                                            <span className='mt-1'>
                                                {(sort == "email" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "email" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "email") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('location')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Location</span>
                                            <span className='mt-1'>
                                                {(sort == "email" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "email" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "email") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('incident_type')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Incident Type</span>
                                            <span className='mt-1'>
                                                {(sort == "email" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "email" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "email") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('status')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Status</span>
                                            <span className='mt-1'>
                                                {(sort == "email" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "email" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "email") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th className='py-3' style={{ cursor: 'pointer' }} onClick={() => {
                                        setSort('reviewer')
                                        setAscending(!ascending)
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='mr-1'>Reviewer</span>
                                            <span className='mt-1'>
                                                {(sort == "email" && ascending == true) && <i className="bx bx-sort-up" style={{ fontSize: '16px' }}></i>}
                                                {(sort == "email" && ascending == false) && <i className="bx bx-sort-down" style={{ fontSize: '16px' }}></i>}
                                                {(sort != "email") && <i className="bx bx-list-ol" style={{ fontSize: '16px' }}></i>}
                                            </span>
                                        </div>
                                    </th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataListofWhistleblowing ? dataListofWhistleblowing.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className='py-2' style={{ wordBreak: 'break-all' }}>{index + 1}</td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.report_id}</td> {/* id_report */}
                                                <td style={{ wordBreak: 'break-all' }}>{item?.disclosure}</td> {/* disclosure */}
                                                <td style={{ wordBreak: 'break-all' }}>{item?.location}</td> {/* location */}
                                                <td style={{ wordBreak: 'break-all' }}>
                                                    {/* {item?.type.map((type, idx) => (
                                                        <button
                                                            key={idx}
                                                            style={{
                                                                color: '#313233',
                                                                backgroundColor: '#EDEFF0',
                                                                border: 'none',
                                                                cursor: 'pointer',
                                                                wordBreak: 'break-all',
                                                                width: '70%',
                                                                textAlign: 'center',
                                                                marginBottom: '4px'
                                                            }}
                                                        >
                                                            {type}
                                                        </button>
                                                    ))} */}
                                                    {item?.type}
                                                </td> {/* incident type */}
                                                <td style={{ wordBreak: 'break-all' }}>
                                                    <span
                                                        className={`status-pill ${item?.status?.toLowerCase().replace(/\s+/g, '-')}`}
                                                        style={{
                                                            color: '#FFFFFF',
                                                            backgroundColor: item?.status === 'Completed' ? '#54803F' : item?.status === 'In Review' ? '#043B72' : '#4880EE',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                            wordBreak: 'break-all',
                                                            width: '70%',
                                                            textAlign: 'center',
                                                            display: 'inline-block',
                                                            padding: '5px 10px',
                                                            borderRadius: '20px'
                                                        }}
                                                    >
                                                        {item?.status}
                                                    </span> {/* status */}
                                                </td>
                                                <td style={{ wordBreak: 'break-all' }}>{item?.reviewer}</td> {/* reviewer */}
                                                <td style={{ wordBreak: 'break-all' }}>
                                                    <button className='btn btn-primary my-n2' onClick={() => {
                                                        history.push(`/whistleblow/report-detail/${item?.report_id}`)
                                                    }}> Detail
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                        : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row mt-4 font-family-spoqa">
                <div className="col-lg-7 col-sm-12 col-xs-12 my-2">
                    <div style={{ display: 'flex' }}>
                        <span className="mt-3 mx-2">Show</span>
                        <span>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
                                <SelectMui
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={size}
                                    onChange={handleChange}
                                    label="Limit">
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </SelectMui>
                            </FormControl>
                        </span>
                        <span className="mt-3 mx-2">
                            Entries
                        </span>
                    </div>
                </div>
                <div className="col-lg-5 col-sm-12 col-xs-12 my-2" style={{ display: 'flex', justifyContent: 'end' }}>
                    <Pagination count={totalPage} color="primary" page={page} showFirstButton showLastButton onChange={handleChangePage} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ document }: IRootState) => ({

});

const mapDispatchToProps = {

};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(ReportList);