import axios from 'axios';
import { getBasePath, Storage, translate } from 'react-jhipster';
// let history = useHistory();

import { SERVER_API_URL } from 'app/config/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;


const redirect = () => {
  // let history = useHistory()
  window.location.href = `/`;
}

const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    config.mode = 'no-cors';
    config.referrerPolicy = 'no-referrer-when-downgrade';

    if(localStorage.getItem("access_token") != null){
      config.headers['Authorization'] = "Bearer "+localStorage.getItem("access_token");
    }
    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);

    if (status === 403 ) {
      localStorage.removeItem("access_token")
      onUnauthenticated();
    }

    // if (status === 404){
    //   window.location.href = '/404'
    // }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
