import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';

const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]


import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Button } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSignature } from './../../entities/signature/signature.reducer'
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import Preview from '../pdf/preview';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import XCircleIcon from 'app/component/x-circle-icon';
import CheckCircleIcon from 'app/component/check-circle-icon';
import SignatureIcon from 'app/component/signature-icon';
import SignatureIconWhite from 'app/component/signature-icon-white';



export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const SignaturePage = (props: IDocumentProps) => {
  // const { account } = props;

  const [category, setCategory] = useState<any>(0)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(5)
  const [selected, setSelected] = useState(list[0])




  useEffect(() => {
    // props.getSignature()
  }, []);




  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = 'https://giardino-collection.com/wp-content/uploads/2021/11/noimage.png';
    event.currentTarget.onerror = null;
  };






  const { documentList, loading } = props;
  return (


    <div className="padding-wrap-sign font-family-spoqa" style={{ height: '80vh' }}>

      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-28">Signature </h1>
          </div>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h1 className="mb-0 font-size-14">View Your Signature </h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">

        <div className='shadow-sm d-flex wrapSign'>
          <img className="rounded mx-auto d-block bg-white" style={{ width: '300px', height: '200px' }} src={`${window.location.origin}/services/uaadocservice/api/personal-identity/get-signature`}
            alt="Sign" onError={(e) => imageOnErrorHandler(e)} />
        </div>

        <div className='my-3'>
          <button className='btn btn-secondary mr-2 px-4' onClick={() => props.history.goBack()}>Back</button>
          {/* <button className='btn btn-danger mr-2 px-3'><XCircleIcon className='mr-1'/>Delete</button> */}
          <a href="/profile/signature/add" className='btn btn-primary px-3 mr-2'><SignatureIconWhite className='mr-1' /> Add Signature</a>
          <a href="/profile/signature/upload" className='btn btn-primary px-3'><SignatureIconWhite className='mr-1' /> Upload Signature</a>
        </div>




      </div>

    </div >

  );
};

const mapStateToProps = ({ document }: IRootState) => ({
  documentList: document.documentDashboardList,
  documentListAcknowledge: document.documentListAcknowledge,
  documentListSuratMasuk: document.documentListSuratMasuk,
  documentListSuratKeluar: document.documentListSuratKeluar,
  loading: document.loading,
  totalApproval: document.totalApproval,
  totalSuratMasuk: document.totalSuratMasuk,
  totalAcknowledge: document.totalAcknowledge,
  totalSuratKeluar: document.totalSuratKeluar,
  totalPageApproval: document.totalPageApproval,
  totalPageAcknowledge: document.totalPageAcknowledge,
  totalPageKotakMasuk: document.totalPageKotakMasuk,
  totalPageKotakKeluar: document.totalPageKotakKeluar
});


const mapDispatchToProps = {
  // getSignature
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(SignaturePage);
