import '../../../../../../scss/bootstrap.min.scss'
import '../../../../../../scss/app.min.scss'
import './home.scss';
import { renderToString } from "react-dom/server";
import { render } from "react-dom";
import tableAppr from 'app/modules/home/table'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { PDFViewer } from '@react-pdf/renderer';


const list = [
  { value: 2 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
]


import React, { useEffect, useState, Fragment } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { CardGroup, Row, Col, Alert, Tooltip, Button } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';


import SideBar from 'app/shared/layout/sidebar/sidebar';
import Approval from 'app/shared/layout/card/approval';
import HandshakeIcon from 'app/component/handshake-icon';
import EyeIcon from 'app/component/eye-icon';
import EnvelopeIcon from 'app/component/envelope-icon';
import PaperPlaneIcon from 'app/component/paperplane-icon';
import InfoIcon from 'app/component/InfoIcon';

import { getSignature } from './../../entities/signature/signature.reducer'
import Axios from 'axios';
import Page from 'app/component/pagination/Page';
import Preview from '../pdf/preview';
import ArrowRightIcon from 'app/component/arrow-right-icon';
import XCircleIcon from 'app/component/x-circle-icon';
import CheckCircleIcon from 'app/component/check-circle-icon';
import SignatureIcon from 'app/component/signature-icon';
import SignatureIconWhite from 'app/component/signature-icon-white';
import LogoutIcon from 'app/component/logout-icon';
import PinIcon from 'app/component/pin-icon';
import { toast } from 'react-toastify';
import { logout } from 'app/shared/reducers/authentication';
import { useHistory } from "react-router-dom";
import UserOutline from 'app/component/user-outline';
import { Storage } from 'react-jhipster';
import LockIcon from "app/component/lock-icon";

export interface IDocumentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const ProfilePage = (props: IDocumentProps) => {
  // const { account } = props;
  let history = useHistory();
  const [category, setCategory] = useState<any>(0)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(5)
  const [selected, setSelected] = useState(list[0])
  const [hasPin, setHasPin] = useState(false)

  useEffect(() => {
    getPIN()
  }, []);

  const getPIN = () => {
    Axios.get(`services/uaadocservice/api/personal-identity/has-pin-approval`)
      .then(res => {
        setHasPin(res.data)
      }).catch(err => {
        console.log(err)
      })
  }

  const goToSignature = () => {
    history.push('/profile/signature/view')
  }

  const goToChangePin = () => {
    history.push('/profile/pin/change')
  }

  const goToSetPin = () => {
    history.push('/profile/pin/set')
  }

  const goToChangePass = () => {
    history.push('/profile/password/change')
  }

  const handleLogout = async () => {
    Storage.local.set("isLoggedInDMS", "false")
    localStorage.removeItem('access_token');
    await props.logout()
    await history.replace('/')
    await delayTime(2000)
    await toast.success('Berhasil Logout', {
      position: "top-left",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    Storage.session.remove(`termandconditions`);
    Storage.session.remove(`change_password`);
    Storage.session.remove(`change_password_username`);
  }

  const delayTime = (ms) => {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    )
  }

  return (
    <div className="padding-wrap-sign font-family-spoqa" style={{ height: '80vh' }}>
      <div className="container-fluid">
        <div className="card py-4 px-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img className="rounded-circle header-profile-user-mobile text-center" src={'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'}
            alt="Header Avatar" />
          <h4 className="text-center my-2">
            {props.account?.firstName}
          </h4>
          <h5 className="text-center my-2">
            {props.account?.email}
          </h5>
        </div>

        {/* <div className="card px-3 py-2"  style={{cursor: 'pointer'}} onClick={() => goToSignature()}>
              <p className="py-2 m-0"> <UserOutline className="mr-2"/> Change Photo <ArrowRightIcon className="float-right mt-1"/></p>
          </div>
      */}
        <div className="card px-3 py-2" style={{ cursor: 'pointer' }} onClick={() => goToSignature()}>
          <p className="py-2 m-0"> <SignatureIcon className="mr-2" /> Signature <ArrowRightIcon className="float-right mt-1" /></p>
        </div>


        {
          hasPin ? <div className="card px-3 py-2" style={{ cursor: 'pointer' }} onClick={() => goToChangePin()}>
            <p className="py-2 m-0"> <PinIcon className="mr-2" /> Change Pin <ArrowRightIcon className="float-right mt-1" /></p>
          </div> : <div className="card px-3 py-2" style={{ cursor: 'pointer' }} onClick={() => goToSetPin()}>
            <p className="py-2 m-0"> <PinIcon className="mr-2" /> Set Pin <ArrowRightIcon className="float-right mt-1" /></p>
          </div>
        }

        <div className="card px-3 py-2" style={{ cursor: 'pointer' }} onClick={() => goToChangePass()}>
          <p className="py-2 m-0"> <LockIcon className="mr-2" /> Change password <ArrowRightIcon className="float-right mt-1" /></p>
        </div>

        <div className="card px-3 py-2" style={{ cursor: 'pointer' }} onClick={() => handleLogout()}>
          <p className="py-2 m-0"> <LogoutIcon className="mr-2" /> Logout <ArrowRightIcon className="float-right mt-1" /></p>
        </div>

      </div>
    </div >

  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account
});


const mapDispatchToProps = {
  // getSignature,
  logout
};
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = typeof mapDispatchToProps;;

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
