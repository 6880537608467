import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import {login, loginKong} from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';

import { Translate, translate, Storage } from 'react-jhipster';
import { Button, Label, Alert, Row, Col } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';
import { Brand } from 'app/shared/layout/header/header-components';
import Illustration1 from 'app/component/illustration-1';
import HeaderPublic from 'app/shared/layout/header-public/header';
import useWindowDimensions from 'app/utils/ScreenDimesions';
import EyeSmallIconDark from 'app/component/eye-small-icon-dark';
import EyeSmallIconDarkHide from 'app/component/eye-small-icon-dark-hide';
import HCaptcha from "@hcaptcha/react-hcaptcha";

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export const Loginx = (props: ILoginProps) => {
  let history = useHistory();

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [charPassCount, setCharPassCount] = useState<any>('')
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);

  const { height, width } = useWindowDimensions();
  const handleSubmit = (event, errors, { username, password, rememberMe }) => {
    handleLogin(username, password, rememberMe, captchaToken);
  };
  const handleLogin = (username: string, password: string, rememberMe = false, captchaToken: string) => {

    if(!captchaToken && process.env.REACT_APP_HCAPTCHA_METHOD =='true'){
      setCaptchaError(true);
      return;
    }

    if (process.env.REACT_APP_USE_KONG == 'true') {
      return props.loginKong(username, password, rememberMe, captchaToken);
    } else {
      return props.login(username, password, rememberMe);
    }

  };
  const handleCaptchaSuccess = (token) => {
    setCaptchaToken(token);
    setCaptchaError(false);

  };
  const handleClose = () => {
    props.history.push('/');
  };
  const pressForgotPassword = () => {
    props.history.push('/forgotpassword')
    window.location.reload();
  };

  const { location, isAuthenticated, needChangePassword } = props;

  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search, username: props.username } };


  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <HeaderPublic />
      <div style={width < 425 ? { height: '90vh', background: '#fff', overflowY: 'scroll' } : { height: '90vh', background: '#fff' }} className="mt-5 py-5 container-lg font-family-spoqa">
        <AvForm onSubmit={handleSubmit}>
          <Row>
            <Col md="6" className="py-5 mr-5">
              <Illustration1 className='mt-4' />
              <div className='my-4'>
                <h1 className='font-weight-bold'>
                  Document Management System
                </h1>
                <p className='h4'>
                  Mirae Asset Sekuritas
                </p>
              </div>
            </Col>
            <Col md="5" className="card py-5 px-4">
              <Row>
                <Col md="12">
                  <Brand />
                  <h3 className='my-3 font-weight-bold'>Login</h3>
                  <p className='h5'>Input employee number and password to enter the application</p>
                  <AvField
                    name="username"
                    label={translate('global.form.username.label')}
                    placeholder={translate('global.form.username.placeholder')}
                    required
                    errorMessage="Username cannot be empty!"
                    autoFocus
                  />
                  <AvField
                    name="password"
                    label={translate('login.form.password')}
                    placeholder={translate('login.form.password.placeholder')}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) =>
                      setCharPassCount(e.target.value.length)
                    }
                  />
                  {
                    !showPassword ? <EyeSmallIconDark style={{ position: 'absolute', top: '87%', right: '5%', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} /> : <EyeSmallIconDarkHide onClick={() => setShowPassword(!showPassword)} style={{ position: 'absolute', top: '87%', right: '5%', cursor: 'pointer' }} />
                  }
                  {charPassCount === 0
                    ?
                    <div style={{ position: 'absolute', top: '96%', fontSize: '10px', color: '#f46a6a' }}>Password cannot be empty!</div>
                    :
                    <></>
                  }
                </Col>
              </Row>
              <div className='float-left text-left px-0'>
                <div className="mb-4 mt-2" style={{ color: '#F37F26', cursor: 'pointer' }} onClick={pressForgotPassword}  >
                  Forgot Password ?
                </div>
                {/* <Link to="/forgot/password" style={{ color: '#F37F26' }} className="my-2">
                  Forgot Password ?
                </Link> */}
              </div>
              {process.env.REACT_APP_HCAPTCHA_METHOD =='true' && (
                  <div >
                    <HCaptcha
                        sitekey={process.env.REACT_APP_HCAPTCHA_SECRET}
                        onVerify={handleCaptchaSuccess}
                    />

                  </div>
              )}
              {captchaError
                  ?
                  <div style={{ position: 'absolute', top: '79%', fontSize: '10px', color: '#f46a6a' }}>hcaptcha cannot be empty!</div>
                  :
                  <></>
              }

              <Button color="primary" type="submit" className="py-3 h2 text-white font-weight-bold mt-4 rounded" style={{ backgroundColor: "#0F2D3C", borderColor: '#0F2D3C' }}>
                <Translate contentKey="login.form.button">Sign in</Translate>
              </Button>
            </Col>
          </Row>
        </AvForm>
      </div>
    </>
  );;
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
  needChangePassword: authentication.needChangePassword,
  username: authentication.username,
});

const mapDispatchToProps = { login, loginKong };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Loginx);
